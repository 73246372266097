import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class FireStoreService {

  dbPath = '/products';

  dbReference: AngularFirestoreCollection;

  constructor(
    private fireStore: AngularFirestore
  ) {}

  getFireStore(){
    return this.fireStore;
  }

  create(data: any, dbPath: string){
    return this.fireStore.collection(dbPath).add({...data});
  }

  edit(data: any, dbPath: string, id: string){
    this.fireStore.collection(dbPath).doc(id).update({...data});
  }

  getList(dbPath: string):Observable<any[]> {
    let list = [];
    this.fireStore.collection(dbPath).get().subscribe( snapshot => {
      snapshot.forEach(doc => {
        list.push({id: doc.id, ... doc.data()});     // Join id document whit data 
      });
    });
    return of(list);
  }

  getOrderedList(dbPath: string):Observable<any[]> {
    const list = [];
    this.fireStore.collection(dbPath).ref.orderBy('position').get().then( snapshot => {
      snapshot.forEach(doc => {
        list.push({id: doc.id, ... doc.data()});     // Join id document whit data 
      });
    });
    return of(list);
  }

  getOrderedByTimestamoList(dbPath: string):Observable<any[]> {
    const list = [];
    this.fireStore.collection(dbPath).ref.orderBy('date').get().then( snapshot => {
      snapshot.forEach(doc => {
        list.push({id: doc.id, ... doc.data()});     // Join id document whit data 
      });
    });
    return of(list);
  }

  getListWithQuery(dpPath: string,field: string, operator: any, value:any) {
    let list = [];
    this.fireStore.collection(dpPath).ref.where(field,operator,value).get().then( snapshot =>{
      snapshot.forEach(doc => {
        list.push({id: doc.id, ...doc.data()})
      }); 
    });
    return of(list);
  }

  getObject(dbPath: string,id: string){
    return this.fireStore.collection(dbPath).doc(id).get();
  }

  getObjectWithQuery(dbPath: string, field: string, operator: any, value: any){
    return this.fireStore.collection(dbPath).ref.where(field,operator,value).get();
  }

  delete(id: string, dbPath: string){
    this.fireStore.doc(dbPath + '/' + id).delete();
  }

}
