import { FireStoreService } from 'src/app/shared/firebase/firebase-services/fireStore.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'promo-section',
  templateUrl: './promo-section.component.html',
  styleUrls: ['./promo-section.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PromoSection implements OnInit{
  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    items: 1,
    nav: false,
    autoplay: true,
    autoplaySpeed: 1000,
    autoWidth: true
  }
  public offers: any[];
  public dbPath: string = '/offers';
  public whatsappMessage = '';

  constructor(private fireStoreService: FireStoreService){
    this.getOffers();
  }

  ngOnInit() {
  }

  getOffers() {
    this.fireStoreService.getListWithQuery(this.dbPath, 'offerSection', '==', true).subscribe(response => {
      if(response) this.offers = response;
    });
  }

  getData(event) {
    if(this.offers && event.slides[0]) {
      const activeOffer = this.offers.filter((offer) => offer.id === event.slides[0].id)[0];
      this.whatsappMessage = activeOffer.whatsappMessage;
    }
  }

  openWhatsapp(){
    const link = `https://api.whatsapp.com/send?phone=59169412093&text=${this.whatsappMessage}`;
    window.open(link, '_blank');
  }
}
