import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { Dialog } from '../../confirm-modal/Model/Dialog';
import { ShoppingCartService } from '../shopping-cart-service/shopping-cart.service';

@Component({
  selector: 'app-finally-section',
  templateUrl: './finally-section.component.html',
  styleUrls: ['./finally-section.component.scss']
})
export class FinallySectionComponent implements OnInit {

  @Input() productsCart
  @Output()
  public reset: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public toggle: EventEmitter<any> = new EventEmitter<any>();
  public _shoppingCartService: ShoppingCartService;

  nameDay: string;

  constructor(public dialog: MatDialog,
    private shoppingCartService: ShoppingCartService) {
    this._shoppingCartService = shoppingCartService;
  }

  ngOnInit() {
    const dateToday = new Date();

    
    const days = [
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes'
    ];
    var numberDay = new Date(dateToday).getDay();
    numberDay-=1
    if (numberDay >= 3) {
      if (numberDay == 3) {
        this.nameDay = "lunes";
      }
      if (numberDay == 4) {
        this.nameDay = "martes";
      }
      numberDay+=2
    }
    else {
      numberDay += 2;
      this.nameDay = days[numberDay];
    }
  }

  openDialogConfirm(): void {
    let dialog: Dialog = new Dialog;
    dialog.titulo = 'Faltan Datos';
    dialog.mensaje = 'Usted no cuenta con los requisitos necesarios para continuar.';
    dialog.btnNo = 'Volver';
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      width: 'auto', data: dialog
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    });
  }

  public orderFinished() {
    this.productsCart.finish = true;

  }

  openDialogConfirmFinally(): void {
    this.shoppingCartService.sendToDataBase();
    let dialog: Dialog = new Dialog;
    dialog.titulo = '¡Gracias!';
    dialog.mensaje = 'Usted completo con éxito la solicitud de pedido, su pedido sera entregado hasta el día ' + this.nameDay + ', nos comunicaremos con usted para definir detalles de la entrega.';
    dialog.btnNo = 'Entendido';
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      width: 'auto', data: dialog
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    });
  }

  public getTotalPrice(num1: number, num2: number): number {
    return num1 * num2;
  }

  public setReset() {
    this.reset.emit(true);
  }

  public setToggle() {
    this.setReset();
    this.toggle.emit(true);
    this.openDialogConfirmFinally();
  }
}
