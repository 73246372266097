import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlideshowModule } from 'ng-simple-slideshow';

//important modules
import { CoreModule } from './core/core.module';
import { HomeComponent } from './components-web/home/home.component';
import { AboutUsComponent } from './components-web/about-us/about-us.component';
import { ContactComponent } from './components-web/contact/contact.component';

//material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { FirebaseModule } from './shared/firebase/firebase.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutUsComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    RouterModule,
    SlideshowModule,
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    HttpClientModule,
    AngularFireAuthGuardModule,
    FirebaseModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
