import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searcher'
})
export class SearcherPipe implements PipeTransform {

  transform(value: any, list: any[], ...args: any[]): any {
    if (!args) { return value; }
    value = value.toLowerCase();
    return args[0].filter(item => {
      if (value !== '' && list.length > 0) {
        if ((item.name.toLowerCase().trim().includes(value)) && (list.includes(item.category))) {
          return item;
        }
      } else if (value !== '') {
        if (item.name.toLowerCase().trim().includes(value)) {
          return item;
        }
      } else if (list.length > 0) {
        if (list.includes(item.category)) {
          return item;
        }
      }
    });
  }

}
