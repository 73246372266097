import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';

@NgModule({
    declarations: [],
    imports: [       
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule    
    ],
    exports: [
        AngularFireModule,
        AngularFireAuthModule,
        AngularFirestoreModule
    ],
    providers: [
        AngularFirestore,
    ],
  })
  export class FirebaseModule { }
  