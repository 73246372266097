import { FireStoreService } from 'src/app/shared/firebase/firebase-services/fireStore.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  
  id: string;
  dbPath: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private firebaseStoreService: FireStoreService
  ) {}

  ngOnInit() {
    this.getParams();
  }

  getParams(){
    this.id = this.activatedRoute.snapshot.queryParamMap.get('id') || 'All';
    this.dbPath = this.activatedRoute.snapshot.queryParamMap.get('dbPath') || 'All';
  }

  return(){
    this.location.back();
  }

  delete(){
    this.firebaseStoreService.delete(this.id,this.dbPath);
    this.location.back();
  }



}
