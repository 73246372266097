import { FireStoreService } from 'src/app/shared/firebase/firebase-services/fireStore.service';
import { Component, OnInit, ViewEncapsulation, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'month-promo-modal',
  templateUrl: './month-promo-modal.component.html',
  styleUrls: ['./month-promo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MonthPromoModalComponent implements OnInit {
  
  private dbPath: string = '/offers';
  public offer: any;

  constructor(
    private firebaseStoreService: FireStoreService,
    public dialogRef: MatDialogRef<MonthPromoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.getData();
  }

  private async getData() {
    const elements = await this.firebaseStoreService.getObjectWithQuery(this.dbPath, "monthOffer", "==", true);
    elements.forEach(doc => {
      this.offer = doc.data();
    });
  }

  @HostListener('document:keydown.scape', ['$event'])
  closeModal(): void {
    this.dialogRef.close();
  }
  
  sendWhatsapp(message: string): void {
    const link = `https://api.whatsapp.com/send?phone=59169412093&text=${message}`;
    window.open(link, '_blank');
  }
}
