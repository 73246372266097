import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ShoppingCartService } from '../shopping-cart-service/shopping-cart.service';

@Component({
  selector: 'app-shopping-cart-button',
  templateUrl: './shopping-cart-button.component.html',
  styleUrls: ['./shopping-cart-button.component.scss']
})
export class ShoppingCartButtonComponent implements OnInit {

  @Output()
  public sidenav: EventEmitter<any> = new EventEmitter<any>();

  public productsCart: any;

  constructor(private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
    this.productsCart = this.shoppingCartService.productsCart;
  }

  public onClick(event): void {
    this.sidenav.emit(event);
  }

}
