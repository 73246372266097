import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {

  transform(value: any[]): any[] {
    if (!value) { return null; }
    return value.sort((a, b) => {
      if (a.position && b.position) {
        return a.position - b.position;
      }
      if (a.position) {
        return -1;
      }
      if (b.position) {
        return 1;
      }
      return 0;
    });
  }
}
