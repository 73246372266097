import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  year: any;
  constructor() { 
    const date = new Date();
    this.year = date.getFullYear();
  }

  ngOnInit() {
    AOS.init({
      duration: 1500,
    });
  }

  scrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  changeNavTab(id) {
    const element = document.getElementById(id);
    const pressed = document.getElementsByClassName('pressed');
    if (element !== pressed[0]) {
      pressed[0].classList.remove('pressed');
      element.classList.add('pressed');
    }
    this.scrollTop();
  }

}
