
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class FireAuthService {

  user: User;

  constructor(private angularFireAuth: AngularFireAuth, private router: Router) { 
    this.angularFireAuth.authState.subscribe(user => {
      this.saveUserInLocalStorage(user);
    });
  }

  async  login(email: string, password: string) {
    return await this.angularFireAuth.auth.signInWithEmailAndPassword(email, password);
  }

  get isLoggedIn() {
    return this.angularFireAuth.auth.currentUser !== null;
  }

  getAngualrFireAuth() {
    return this.angularFireAuth.auth;
  }

  saveUserInLocalStorage(user: User){
    if (user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      localStorage.setItem('user', null);
    }
  }

  async logout() {
    await this.angularFireAuth.auth.signOut();
    localStorage.removeItem('user');
  }


}
