import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


//components
import { NavbarComponent } from './navbar/navbar.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { FooterComponent } from './footer/footer.component';
import { WhatsappButtonComponent } from './whatsapp-button/whatsapp-button.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ProductSectionComponent } from './shopping-cart/product-section/product-section.component';
import { InfoSectionComponent } from './shopping-cart/info-section/info-section.component';
import { FinallySectionComponent } from './shopping-cart/finally-section/finally-section.component';
import { ShoppingCartButtonComponent } from './shopping-cart/shopping-cart-button/shopping-cart-button.component';



@NgModule({
  declarations: [
    NavbarComponent,
    WrapperComponent,
    FooterComponent,
    WhatsappButtonComponent,
    WrapperComponent,
    FooterComponent,
    ShoppingCartComponent,
    ConfirmModalComponent,
    ProductSectionComponent,
    InfoSectionComponent,
    FinallySectionComponent,
    ShoppingCartButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatGridListModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule
  ],
  exports: [
    WrapperComponent,
    NavbarComponent,
    WrapperComponent,
    FooterComponent
  ],
  entryComponents: [
    ConfirmModalComponent
  ]
})
export class CoreModule {

}
