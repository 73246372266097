import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailService } from 'src/app/shared/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm
  email: AbstractControl;
  message: AbstractControl;



  constructor(
    private formBuilder: FormBuilder,
    private contactService: EmailService,
    private router: Router,
  ) {
    this.buildForm();
  }

  buildForm() {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
    this.email = this.contactForm.controls.email;
    this.message = this.contactForm.controls.message;
  }

  ngOnInit() {
    this.changeNavTab('contact');
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const emailRequest = {
        from: this.contactForm.value.email,
        subject: 'Enviado desde la pagina de contacto de Etix',
        text: this.contactForm.value.message,
        to: 'empresa.etix@gmail.com'
      };
      this.contactService.sendEmail(emailRequest).subscribe();
      this.router.navigate(['/']);
    }
  }


  closeMenu() {
    const menuOptions = document.getElementById('menuMobile');
    const mainContainer = document.getElementsByClassName('main-container')[0];
    mainContainer.classList.remove('blur');
    menuOptions.classList.add('hidden');
  }

  changeNavTab(id) {
    const element = document.getElementById(id);
    const pressed = document.getElementsByClassName('pressed');
    if (element !== pressed[0]) {
      pressed[0].classList.remove('pressed');
      element.classList.add('pressed');
    }
    this.scrollTop();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

}
