import { Component, OnInit } from '@angular/core';
import AOS from 'aos';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.changeNavTab("about");
    AOS.init({
      duration: 1500,
    });
  }

  closeMenu() {
    const menuOptions = document.getElementById('menuMobile');
    const mainContainer = document.getElementsByClassName('main-container')[0];
    mainContainer.classList.remove('blur');
    menuOptions.classList.add('hidden');
  }

  changeNavTab(id) {
    const element = document.getElementById(id);
    const pressed = document.getElementsByClassName('pressed');
    if (element !== pressed[0]) {
      pressed[0].classList.remove('pressed');
      element.classList.add('pressed');
    }
    this.scrollTop();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

}
