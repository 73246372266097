import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShoppingCartService } from './shopping-cart-service/shopping-cart.service';


@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {

  @Output()
  public sidenav: EventEmitter<any> = new EventEmitter<any>();
  isLinear = true;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  message: any;

  productsCart: any;

  constructor(private _formBuilder: FormBuilder,
    private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
    this.productsCart = this.shoppingCartService.productsCart;
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  receiveMessage($event) {
    this.message = $event;
  }

  public onClick(event): void {
    this.sidenav.emit(event);
  }
}
