import { Router } from '@angular/router';
import { FireStoreService } from 'src/app/shared/firebase/firebase-services/fireStore.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import AOS from 'aos';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MonthPromoModalComponent } from 'src/app/shared/components/month-promo-modal/month-promo-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  favoriteProducts: Observable<any[]>;
  dbPath = '/products'
  imageUrlArrayWeb = [
    '../../../assets/images/photos/carrousel/2-old.jpeg',
    '../../../assets/images/photos/carrousel/3-old.jpeg',
    '../../../assets/images/photos/carrousel/1-old.jpeg',
  ];
  imageUrlArrayMobile = [
    '../../../assets/images/photos/carrousel/1-old.jpeg',
    '../../../assets/images/photos/carrousel/2-old.jpeg',
    '../../../assets/images/photos/carrousel/3-old.jpeg',
  ];

  limitScrollPsotion = 1500;
  initialScrollPosition = 400;

  constructor(
    private fireStoreService: FireStoreService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.favoriteProducts = this.getFavoriteProducts();
  }


  ngOnInit() {
    this.changeNavTab('home');
    AOS.init({
      duration: 1500,
    });
    this.openModal();
  
  }

  changeNavTab(id) {
    this.scrollTop();
    const element = document.getElementById(id);
    const pressed = document.getElementsByClassName('pressed');
    if (element !== pressed[0]) {
      pressed[0].classList.remove('pressed');
      element.classList.add('pressed');
    }
    this.scrollTop();
  }

  viewProduct(id, category) {
    this.router.navigate(['/catalog/product'], { queryParams: { id: id, category: category } })
  }


  getFavoriteProducts() {
    return this.fireStoreService.getListWithQuery(this.dbPath, 'favorite', '==', true);
  }

  scrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  closeMenu() {
    const menuOptions = document.getElementById('menuMobile');
    const mainContainer = document.getElementsByClassName('main-container')[0];
    mainContainer.classList.remove('blur');
    menuOptions.classList.add('hidden');
  }

  public openModal(): void {
    const dialogConfig: MatDialogConfig<any> = new MatDialogConfig<any>();
    dialogConfig.width = '550px';
    dialogConfig.height = 'auto';
    dialogConfig.panelClass = "month-modal";
    dialogConfig.backdropClass = "modal-backdrop";
    dialogConfig.hasBackdrop = true;
    this.dialog.open(MonthPromoModalComponent, dialogConfig);
  }
}
