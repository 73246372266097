import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss']
})
export class InfoSectionComponent implements OnInit {

  @Input() productsCart
  verificator = ""
  @Output()
  public next: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public saveFormInfo() {
    this.cleanForm(this.productsCart.form)
    this.productsCart.address = this.productsCart.form.value.address;
    this.productsCart.company = this.productsCart.form.value.company;
    this.productsCart.email = this.productsCart.form.value.email;
    this.productsCart.method = this.productsCart.form.value.method;
    this.productsCart.name = this.productsCart.form.value.name;
    this.productsCart.phone = this.productsCart.form.value.phone;
    this.productsCart.reference = this.productsCart.form.value.reference;
    this.productsCart.nit = this.productsCart.form.value.nit;
    this.productsCart.invoiceName = this.productsCart.form.value.invoiceName;
    this.productsCart.finish = false;


    this.next.emit(true);
  }

  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.trim()));
    this.setState(this.productsCart.secondStepper, false)
  }

  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ "required": true })
    } else {
      control.reset()
    }
  }

}
