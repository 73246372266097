import { LoaderComponent } from './shared/components/loader/loader.component';
import { ContactComponent } from './components-web/contact/contact.component';
import { AboutUsComponent } from './components-web/about-us/about-us.component';
import { HomeComponent } from './components-web/home/home.component';
import { WrapperComponent } from './core/wrapper/wrapper.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './components-admin/auth/guards/auth.guard';
import { WrapperAdminComponent } from './shared/components/wrapper-admin/wrapper-admin.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '', component: WrapperComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },

      {
        path: 'about',
        component: AboutUsComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'catalog',
        loadChildren: () => import('./components-web/catalog/catalog.module').then(m => m.CatalogModule)
      }
    ]
  },

  {
    path: 'login',
    loadChildren: () => import('./components-admin/auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: 'admin', component: WrapperAdminComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: 'product',
        loadChildren: () => import('./components-admin/products/product.module').then(m => m.ProductModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'category',
        loadChildren: () => import('./components-admin/categories/categories.module').then(m => m.CategoriesModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'orders',
        loadChildren: () => import('./components-admin/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
      },
      {
        path: 'offers',
        loadChildren: () => import('./components-admin/offers/offers.module').then(m => m.OffersModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
      }
    ]
  },


  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'loading',
    component: LoaderComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
