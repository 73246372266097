export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCPAuB--q9XT5mZColdGxcyRrZS7I-FRBM",
    authDomain: "angular-etix.firebaseapp.com",
    databaseURL: "https://angular-etix.firebaseio.com",
    projectId: "angular-etix",
    storageBucket: "angular-etix.appspot.com",
    messagingSenderId: "738068790099",
    appId: "1:738068790099:web:6decbde2e2cea26e45ffa6",
    measurementId: "G-5ES7KZ14XS"
  }
};

