import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { copyFileSync } from 'fs';
import { FireStoreService } from 'src/app/shared/firebase/firebase-services/fireStore.service';
import { EmailService } from 'src/app/shared/services/email.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  productsCart = {
    products: [],
    productsTotal: 0,
    minTotal: 350,
    total: 0,
    finish: false,
    address: '',
    company: '',
    email: '',
    method: '',
    name: '',
    phone: '',
    nit: '',
    invoiceName: '',
    reference: '',
    firstStepper: new FormControl(''),
    secondStepper: new FormControl(''),
    form: new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3), this.noWhitespaceValidator]),
      phone: new FormControl('', [Validators.required, Validators.minLength(5), this.noWhitespaceValidator]),
      nit: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.email]),
      invoiceName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      company: new FormControl(''),
      address: new FormControl(''),
      reference: new FormControl(''),
      method: new FormControl('', [Validators.required])
    }),
    methods: ['Efectivo', 'Transferencia', 'QR']
  };

  dbPath = '/orders';
  private priceDbPath = '/price';
  private priceId = 'jcElbtC6Zzh6XZPw4aQT';

  constructor(private fireStoreService: FireStoreService,
    private emailService: EmailService) {
    this.getPrice();
    this.setState(this.productsCart.firstStepper, true);
    this.setState(this.productsCart.secondStepper, true);
  }

  getPrice() {
    this.fireStoreService.getObject(this.priceDbPath, this.priceId).subscribe(response => {
      this.productsCart.minTotal = response.data().minPrice;
    });
  }

  addProductExist(productToAdd) {
    for (const product of this.productsCart.products) {
      if (product.id === productToAdd.id) {
        product.quantity = product.quantity + productToAdd.cant;
        this.productsCart.total += (productToAdd.price * productToAdd.cant);
        if (product.comment == "") {
          product.comment = productToAdd.comment;
        }
        if (this.productsCart.total >= this.productsCart.minTotal) {
          this.setState(this.productsCart.firstStepper, false);
        }
      }
    }
  }

  addProduct(product) {
    if (this.productsCart.products.find(p => p.id === product.id)) {
      this.addProductExist(product);
    } else {
      this.productsCart.total += product.price * product.cant;
      const productToAdd = {
        img: product.img,
        id: product.id,
        price: product.price,
        name: product.name,
        quantity: product.cant,
        comment: product.comment
      };
      this.productsCart.products.push(productToAdd);
      this.productsCart.productsTotal += 1;
      if (this.productsCart.total >= this.productsCart.minTotal) {
        this.setState(this.productsCart.firstStepper, false);
      }
    }

  }

  deleteProduct(productDelete) {
    for (const product of this.productsCart.products) {
      if (product === productDelete) {
        this.productsCart.total -= product.price * product.quantity;
        this.productsCart.productsTotal -= 1;
      }
    }
    this.productsCart.products = (this.productsCart.products.filter(i => i != productDelete));
    if (this.productsCart.total >= this.productsCart.minTotal) {
      this.setState(this.productsCart.firstStepper, false);
    } else {
      this.setState(this.productsCart.firstStepper, true);
    }
  }



  addOneProduct(productToAdd) {
    for (const product of this.productsCart.products) {
      if (product === productToAdd) {
        product.quantity += 1;
        this.productsCart.total += product.price;
      }
    }
    if (this.productsCart.total >= this.productsCart.minTotal) {
      this.setState(this.productsCart.firstStepper, false);
    } else {
      this.setState(this.productsCart.firstStepper, true);
    }
  }

  removeOneProduct(productToRemove) {
    for (const product of this.productsCart.products) {
      if (product.id === productToRemove.id && product.quantity > 1) {
        product.quantity -= 1;
        this.productsCart.total -= product.price;
      }
    }
    if (this.productsCart.total >= this.productsCart.minTotal) {
      this.setState(this.productsCart.firstStepper, false);
    } else {
      this.setState(this.productsCart.firstStepper, true);
    }
  }

  sendToDataBase() {
    const productCartDB = {
      products: [],
      date: new Date(),
      totalPrice: 0,
      status: "Nuevo",
      data: {
        address: '',
        company: '',
        email: '',
        method: '',
        name: '',
        phone: '',
        reference: '',
        nit: '',
        invoiceName: ''
      }
    };
    productCartDB.data = {
      address: this.productsCart.address,
      company: this.productsCart.company,
      email: this.productsCart.email,
      method: this.productsCart.method,
      name: this.productsCart.name,
      phone: this.productsCart.phone,
      reference: this.productsCart.reference,
      nit: this.productsCart.nit,
      invoiceName: this.productsCart.invoiceName
    };
    productCartDB.totalPrice = this.productsCart.total;
    for (const product of this.productsCart.products) {
      const productDB = {
        id: product.id,
        quantity: product.quantity,
        name: product.name,
        img: product.img,
        price: product.price,
        total: product.price * product.quantity,
        comment: product.comment
      };
      productCartDB.products.push(productDB);
    }


    this.fireStoreService.create(productCartDB, this.dbPath).then(res => {
      let shortID = res.id.substring(0,5)
      if (productCartDB.data.email) {

        this.sendMail(productCartDB.data.email, 'Se registro tu pedido', this.createClientEmail(shortID));
      }
      this.sendMail('pedidos.etix@gmail.com',
        'Se registro un nuevo pedido en la pagina.',
        this.createEtixEmail(shortID, productCartDB.data));
    });


    this.resetProductCart();
  }

  editOrder(order, id) {
    this.fireStoreService.edit(order, this.dbPath, id)
  }

  resetProductCart() {
    this.productsCart.products = [];
    this.productsCart.productsTotal = 0;
    this.productsCart.minTotal = 350;
    this.productsCart.total = 0;
    this.productsCart.finish = false;
    this.productsCart.address = '';
    this.productsCart.company = '';
    this.productsCart.email = '';
    this.productsCart.method = '';
    this.productsCart.name = '';
    this.productsCart.phone = '';
    this.productsCart.nit = '';
    this.productsCart.invoiceName = '';
    this.productsCart.firstStepper = new FormControl('');
    this.productsCart.secondStepper = new FormControl('');
    this.productsCart.reference = '';
    this.productsCart.form = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3), this.noWhitespaceValidator]),
      phone: new FormControl('', [Validators.required, Validators.minLength(5), this.noWhitespaceValidator]),
      nit: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      invoiceName: new FormControl('', [Validators.required, this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.email]),
      company: new FormControl(''),
      address: new FormControl(''),
      reference: new FormControl(''),
      method: new FormControl('', [Validators.required])
    });
    this.getPrice();
    this.setState(this.productsCart.firstStepper, true);
    this.setState(this.productsCart.secondStepper, true);
  }

  private noWhitespaceValidator(control: FormControl): { whitespace: boolean; } | null {
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ required: true });
    } else {
      control.reset();
    }
  }

  createClientEmail(id: string): string {
    return `Tu pedido fue recibido correctamente

      Un representante de la empresa se contactara contigo para coordinar los detalles de la entrega.
      El ID de tu pedido es: ${id}

      Este correo electrónico fue enviado automaticamente por el sistema de ETIX`;
  }

  createEtixEmail(id: string, contactData: any): string {
    console.log(contactData)
    return `Se acaba de registrar un pedido desde la pagina
        - ID del pedido: ${id}
    Datos del cliente:
        - Nombre: ${contactData.name}
        - Numero de teléfono: ${contactData.phone}
        - NIT/CI: ${contactData.nit}
        - Nombre/Razon: ${contactData.invoiceName}

    El detalle del pedido esta disponible en la aplicacion del administrador, puede buscarlo con el id del pedido.

    Este correo electrónico fue enviado automaticamente por el sistema de ETIX`;
  }

  sendMail(email: string, subject: string, message: string) {
    const emailRequest = {
      from: 'empresa.etix@gmail.com',
      subject,
      text: message,
      to: email
    };
    this.emailService.sendEmail(emailRequest).toPromise();
  }

}
