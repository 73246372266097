import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  openCloseMenu() {
    const menuOptions = document.getElementById('menuMobile');
    const mainContainer = document.getElementsByClassName('main-container')[0];
    if (menuOptions.classList.contains('hidden')) {
      menuOptions.classList.remove('hidden');
      mainContainer.classList.add('blur'); 
    } else {
      menuOptions.classList.add('hidden');
      mainContainer.classList.remove('blur');
    }
  }

  changeNavTab(id) {
    const element = document.getElementById(id);
    const pressed = document.getElementsByClassName('pressed');
    if (element !== pressed[0]) {
      pressed[0].classList.remove('pressed');
      element.classList.add('pressed');
    }
    this.scrollTop();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }
}
