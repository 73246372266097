import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchOrder'
})

export class SearchOrderPipe implements PipeTransform {
  transform(value: any, list: any[], ...args: any[]): any {

    if (!args) return value;
    value = value.toLowerCase();


    return args[0].filter(item => {
      if (value !== '' && list.length > 0) {
        if (item.data.name.toLowerCase().trim().includes(value) || item.id.toLowerCase().trim().includes(value)) {
          return item;
        }
      } else if (value !== '') {
        if (item.data.name.toLowerCase().trim().includes(value) || item.id.toLowerCase().trim().includes(value)) {
          return item;
        }
      }

    });
  }
}
