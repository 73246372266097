import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

//material
import { MaterialModule } from './material/material.module';
import { FirebaseModule } from './firebase/firebase.module';
import {MatDialogModule} from '@angular/material/dialog';

//components
import { NavbarAdminComponent } from './components/navbar-admin/navbar-admin.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SearcherPipe } from './pipes/searcher.pipe';
import { OrderPipe } from './pipes/order.pipe';
import { WrapperAdminComponent } from './components/wrapper-admin/wrapper-admin.component';
import { SearchOrderPipe } from './pipes/search-order.pipe';
import { PromoSection } from './components/promo-section/promo-section.component';
import { PromoCarouselItem } from './components/promo-carousel-item/promo-carousel-item.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MonthPromoModalComponent } from './components/month-promo-modal/month-promo-modal.component';

@NgModule({
  declarations: [
    NavbarAdminComponent,
    DeleteConfirmationComponent,
    LoaderComponent,
    SearcherPipe,
    OrderPipe,
    SearchOrderPipe,
    WrapperAdminComponent,
    SearchOrderPipe,
    PromoCarouselItem,
    PromoSection,
    MonthPromoModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FirebaseModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    CarouselModule,
    MatDialogModule
  ],
  exports: [
    NavbarAdminComponent,
    DeleteConfirmationComponent,
    MaterialModule,
    FirebaseModule,
    ReactiveFormsModule,
    FormsModule,
    LoaderComponent,
    WrapperAdminComponent,
    PromoSection,
    PromoCarouselItem,
    MonthPromoModalComponent
  ],
  entryComponents: [
    MonthPromoModalComponent
  ]
})

export class SharedModule { }
