import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {

  events: string[] = [];
  opened: boolean;
  message: any;

  constructor() { }

  ngOnInit() {
    this.resetComponents();
  }

  receiveMessage($event) {
    this.message = $event;
  }

  resetComponents() {
    const nav = document.getElementById('navbar');
    const foo = document.getElementById('footer');
    nav.classList.remove('hidden');
    foo.classList.remove('hidden');
  }

}
