import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private httpOptions: any;
  private url = 'https://us-central1-angular-etix.cloudfunctions.net/app/email';

  constructor(
    private httpClient: HttpClient
  ) {
    this.setHttpOptions();
  }

  private setHttpOptions() {
    this.httpOptions = {};
  }

  public sendEmail(emailData: any) {
    return this.httpClient.post(this.url, emailData, this.httpOptions).map((response: any) => {
      return response;
    });
  }
}