import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { Dialog } from '../../confirm-modal/Model/Dialog';
import { ShoppingCartService } from '../shopping-cart-service/shopping-cart.service';

@Component({
  selector: 'app-product-section',
  templateUrl: './product-section.component.html',
  styleUrls: ['./product-section.component.scss']
})
export class ProductSectionComponent implements OnInit {

  productsCart: any;

  constructor(public dialog: MatDialog,
    private shoppingCartService: ShoppingCartService) { }

  ngOnInit() {
    this.productsCart = this.shoppingCartService.productsCart;
    this.setState(this.productsCart.firstStepper, true)
    this.setState(this.productsCart.secondStepper, true)
  }

  public addOneProduct(product) {
    this.shoppingCartService.addOneProduct(product);
  }

  public removeOneProduct(product) {
    this.shoppingCartService.removeOneProduct(product);
  }

  public deleteProduct(product) {
    this.shoppingCartService.deleteProduct(product);
  }

  openDialogDelete(product): void {
    let dialog: Dialog = new Dialog;
    dialog.titulo = '¿Eliminar Producto?';
    dialog.mensaje = '¿Esta seguro que desea eliminar el producto del camión de pedidos?';
    dialog.btnSi = 'Si';
    dialog.btnNo = 'Cancelar';
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      height: 'auto',
      width: 'auto', data: dialog
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.deleteProduct(product);
      }

    });
  }
  setState(control: FormControl, state: boolean) {
    if (state) {
      control.setErrors({ "required": true })
    } else {
      control.reset()
    }
  }

}
