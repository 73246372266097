import { Component, Input } from '@angular/core';

@Component({
  selector: 'promo-carousel-item',
  templateUrl: './promo-carousel-item.component.html',
  styleUrls: ['./promo-carousel-item.component.scss']
})
export class PromoCarouselItem {
  @Input('item')
  public item: any;

  formatPrice(price: number): string {
    return Number(price).toFixed(2);
  }
}
