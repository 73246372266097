import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FireAuthService } from './shared/firebase/firebase-services/fireAuth.service';

declare var gtag;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private fireAuthService: FireAuthService,
              private router:Router){
    const navEndEvents$ = this.router.events
    .pipe(
            filter(event => event instanceof NavigationEnd)
          );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-YHHW5W8H5T', {
        'page_path': event.urlAfterRedirects
      });
    });
  }

  isLogged(){
    return this.fireAuthService.isLoggedIn;
  }

}
